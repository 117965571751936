import styled from "styled-components";

export const LoginStyle = styled.div`
  display: flex;
  height: 100vh;
  .title {
    margin-bottom: 56px;
  }
  .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    .form {
      max-width: 300px;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: ${({ theme }) => theme.black.main};
    img {
      width: 300px;
    }
  }
  .code-input {
    input {
      padding: 14px 16px;
      border: none;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
    }
  }
  .login-btn {
    height: 49px;
  }
`;
